import { appConfig } from "./appConfig";


export default [
  {
    "name": "Павильон «Дом полимеров Сибур»",
    "description":  "«Дом полимеров» СИБУРа на ВДНХ — это увлекательное путешествие в мир современной нефтехимии. Экспозиция рассказывает о том, как создаются полимеры и где они применяются: от смартфонов до космических кораблей. ",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/Павильон №12.jpeg`,
    "id": 104,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D7",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/sibur.png",
    "class": "element7",
    "elementInfoClass": "element-info-7"
  },
  {
    "name": "Павильон «Россия – страна возможностей»",
    "description":  "В стенах павильона № 1 в рамках Международной выставки-форума «Россия» было создано уникальное интерактивное пространство: «Россия — страна возможностей». Восемь его залов посвящены достижениям России через отражение прошлого, настоящего и будущего.  ",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/Павильон №57. «Россия — моя история».jpg`,
    "id": 108,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D8",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/central.webp",
    "class": "element1",
    "elementInfoClass": "element-info-1"
  },
  {
    "name": "Павильон «Атом»",
    "description":  "Павильон «АТОМ» — это выставочный просветительский комплекс с крупнейшей и самой современной в России экспозицией, посвящённой ядерной энергии. Его общая площадь составляет более 25 тысяч квадратных метров, а вместимость — более 2 тысяч человек одновременно.",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/Павильон №19. «Атом».jpeg`,
    "id": 111,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D9",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/atom.png",
    "class": "element8",
    "elementInfoClass": "element-info-8"
  },
  {
    "name": "Павильон «СБЕР»",
    "description":  "Экспозиция в павильоне Сбера на ВДНХ была посвящена истории банка и последним достижениям в области технологий, цифровизации и искусственного интеллекта. ",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/Павильон Робостанция.jpg`,
    "id": 109,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D2",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/sber.png",
    "class": "element9",
    "elementInfoClass": "element-info-9"
  },
  {
    "name": "Павильон «Умный город»",
    "description":  "Павильон «Умный город» — это мультимедийная выставка новейших цифровых проектов и сервисов Москвы. Его экспозиция демонстрирует, как технологии помогают управлять многомиллионным городом и  какие сервисы помогают горожанам жить в комфорте и безопасности. ",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/Павильон «Умный город».jpg`,
    "id": 106,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D1",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/city.png",
    "class": "element12",
    "elementInfoClass": "element-info-12"
  },
  {
    "name": "Павильон «Города для жизни сбываются, ВЭБ.РФ»",
    "description":  "Экспозицияя ВЭБ.РФ под названием «Города для жизни сбываются» в дни выставки-форума «Россия» рассказывала, как изменилась жизнь в российских городах за последнее двадцатилетие.",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/HighresScreenshot00000.png`,
    "id": 112,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D2",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/veb.png",
    "class": "element10",
    "elementInfoClass": "element-info-10"
  },
  {
    "name": "Павильон «Умные финансы»",
    "description":  "В павильоне «Умные финансы» была представлена экспозиция Центрального банка Российской Федерации. В дни проведения выставки-форума «Россия» посетителям демонстрировали достижения страны в области финансовых и платежных продуктов, сервисов и технологий.",
    "category": "Павильоны и сооружения",
    "imgUrl": `${appConfig.staticDomain}/file/FINANCE_3.png`,
    "id": 110,
    "identificator": "9A7DFB83-0AE2-4CEE-8298-266B5FF7A8D3",
    "cantTeleportation": false,
    "mapIcon": "/mapElements/smart_finance.png",
    "class": "element6",
    "elementInfoClass": "element-info-6"
  }
]