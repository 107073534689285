export const appConfig = {
    staticDomain: process.env.NODE_ENV === 'development' ? 'https://back.metamsk.ru' : process.env.VUE_APP_DOMAIN,
    domain: process.env.VUE_APP_DOMAIN,
    frontendDomain: process.env.VUE_APP_FRONTEND_DOMAIN,
    socket: process.env.VUE_APP_SOCKET,
    stream: process.env.VUE_APP_STREAM,

    isDesktopApp: false,
    isDesktopAppChecked: false,
    desktopSocket: "ws://localhost:4000/",
    instance: "https://stream.metamsk.ru:9000/signallingserver",


    appState: {
        mode: 'test',
        isLockedSomeActions: true,
        acceptedEmails: [
            'svetlana.lobanova@mail.ru',
            'kirill.kot.70@icloud.com',
            'amirov.and@ya.ru',
            'coo@vrt.one',
            'malyshevrt@gmail.com',
            'vitaliy.klykov@gmail.com',
            'GanzVlad1@gmail.com',
            'maraevv@mail.ru',
            'test.email@test.ru',
            'test.email2@test.ru',
            'test.email3@test.ru',
            "alexey.pichugin@gmail.com",
            "edgar.frolenkov@yandex.ru",
            "Demo@msk.ru",
            "guide@z8.ru",
            "lecture@z8.ru",
            "lector@mail.com",
            "dittest1@mail.ru",
            "dittest2@mail.ru",
            "dittest3@mail.ru",
            "dittest4@mail.ru",
            "dittest5@mail.ru",
            "dittest6@mail.ru",
            "dittest7@mail.ru",
            "dittest8@mail.ru",
            "dittest9@mail.ru",
            "dittest10@mail.ru",
        ]
    }
}

export const maps = {
    russia: '/Game/Assets/Vdnh/Buildings/interior_1_Pavilion_video/L_interior_1_Pavilion_video',
}