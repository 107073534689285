<template>
  <div v-if="false" class="help-wrapper" :class="{'disable-actions':trainingStep!=null}">
    <div class="help-filling">
      <div class="help-list-wrapper help-list-modal" :class="{ 'help-list-modal__open': viewOptions }" ref="helpers">
        <div class="help-list">
          <button class="help-element" @click="startTraining">
            <img src="@/assets/icons/game/help-icon-train.svg" alt="" class="help-icon">
            <p class="help-text">
              Обучение
            </p>
            <img src="@/assets/icons/game/help-icon.svg" alt="" class="help-arrow">
          </button>
          <button class="help-element desktop" @click="openMap">
            <img src="@/assets/icons/game/help-icon-nav.svg" alt="" class="help-icon">
            <p class="help-text">
              Навигация</p>
            <img src="@/assets/icons/game/help-icon.svg" alt="" class="help-arrow">
          </button>
          <button class="help-element" @click="viewNavigation = !viewNavigation">
            <img src="@/assets/icons/game/help-icon-about.svg" alt="" class="help-icon">
            <p class="help-text">
              Об объекте
            </p>
            <img src="@/assets/icons/game/help-icon.svg" alt="" class="help-arrow">
          </button>
        </div>
      </div>
      <div class="help__activate-wrapper">
        <button class="help__activate" id="helpersTrigger" @click="viewOptions = !viewOptions" :class="{
          'help__activate-training': viewOptions || trainingStep === 6,
        }">
          <div class="help__activate-icon"/>
        </button>
        <!--        <div class="help-activate-training" v-if="false">-->
        <!--          <div class="help-activate-training-caption">-->
        <!--            <button class="help-activate-training-close" @click="closeTraining">-->
        <!--              Закрыть-->
        <!--              <img src="@/assets/icons/close.svg" alt="" class="help-activate-training-close__icone"/>-->
        <!--            </button>-->
        <!--          </div>-->
        <!--          <div class="help-activate-training-info">-->
        <!--            <p class="help-activate-training-info__title">-->
        <!--              Виртуальный помощник-->
        <!--            </p>-->
        <!--            <p class="help-activate-training-info__text">-->
        <!--              Для поиска информации об объекте или быстрой телепортации нажмите-->
        <!--              на иконку виртуального помощника.-->
        <!--            </p>-->
        <!--          </div>-->
        <!--          <button class="help-activate-training__btn" @click="closeTraining">-->
        <!--            Завершить-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>

    <!-- <div class="search-wrapper">
      <button class="close" @click="viewNavigation = !viewNavigation">
        Закрыть
        <img src="@/assets/icons/close-small.svg" alt="" class="close-white" />
      </button>
      <div class="search-filling">
        <label for="" class="search__fild">
          <input v-model="searchObjName" type="text" class="search__input" placeholder="Поиск" />
          <img src="@/assets/icons/game/surch-icon.svg" alt="" class="search__img" />
        </label>
        <div class="search-popup popup">
          <button class="search-popup__btn" @click="viewSearchResults = !viewSearchResults">
            {{ objectsList.length ? objectsList[0].name : "Ничего не найдено" }}
            <img class="search-popup__btn-icon" src="@/assets/icons/game/pop-up-list.svg"
              :class="{ 'search-popup__btn-icon-active': viewSearchResults }" alt="" />
          </button>
          <div class="search-popup-list popup-list" v-if="viewSearchResults">
            <button v-if="false" class="search-popup__element popup-list__element search-popup__element-selected">
              Фонтан «Дружба народов»
            </button>
            <button class="search-popup__element popup-list__element" @click="currentObj = item"
              v-for="item in objectsList" :key="item.name">
              {{ item.name }}
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <!--    <div class="info-wrapper" v-if="currentObj">-->
    <!--      <button class="close desktop" @click="currentObj = null">-->
    <!--        Закрыть-->
    <!--        <img src="@/assets/icons/close-small.svg" alt="" class="close-white"/>-->
    <!--      </button>-->
    <!--      <div class="info-filling">-->
    <!--        <img :src="currentObj.imgUrl" alt="" class="info__img"/>-->
    <!--        <div class="info-content">-->
    <!--          <p class="info__description desktop">{{ currentObj.category }}</p>-->
    <!--          <div class="info__caption mobile">-->
    <!--            <p class="info__description">{{ currentObj.category }}</p>-->
    <!--            <button class="close" @click="currentObj = null">-->
    <!--              <img src="@/assets/icons/close-small.svg" alt="" class="close-white"/>-->
    <!--            </button>-->
    <!--          </div>-->
    <!--          <p class="info__name">{{ currentObj.name }}</p>-->
    <!--          <p class="info__about">О месте</p>-->
    <!--          <p class="info__text">{{ currentObj.description }}</p>-->
    <!--          <button class="info__btn" v-if="false" @click="teleport(currentObj.id)">-->
    <!--            Телепортироваться-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <NewAboutObject id="aboutObject" :current-object="currentObject" @closeWindow="viewNavigation = false"
                  :locale="locale"
                  v-if="viewNavigation" :vdnx-objects="vdnxObjects" @teleport="teleport"
                  @openMap="openMap" @openObjectInfo="openObjectInfo" @robotGuideEnable="robotGuideEnable">
  </NewAboutObject>
</template>

<script>
import { computed, ref, watch } from "vue";

import NewAboutObject from "@/components/game/NewAboutObject.vue";
import data from "@/lib/data.js";
import locations from "@/lib/locations.json";
import russiaMapElements from "@/lib/russia.js";
import { onClickOutside } from "@vueuse/core";
import { useRoute } from "vue-router";

export default {
  name: "HelpComponent",
  emits: ['closeTraining',
    'startTraining',
    'openMap',
    'robotGuideEnable',],
  components: {
    NewAboutObject
  },
  props: ["trainingStep", 'locale'],
  setup(props, {emit}) {
    //----------------VARIABLES--------------------
    const route = useRoute();
    const isRussia = computed(() =>  route.query?.place?.trim().toLowerCase() === locations.russian?.place?.trim());
    const viewOptions = ref(false);
    const vdnxObjects = computed(() => isRussia.value ? russiaMapElements : data);

    const currentObject = ref(vdnxObjects.value[0]);


    const viewSearchResults = ref(false);

    const helpers = ref(null);


    const openObjectInfo = (val) => {
      // if (currentObject.value?.id === val?.id) {
      //   currentObject.value = null;
      //   return
      // }
      currentObject.value = val;
      // viewNavigation.value = true;
    }

    const teleport = (id) => {
      document.dispatchEvent(new CustomEvent("Teleport", {detail: id}));
      viewOptions.value = false
    };

    const viewNavigation = ref(false);


    const closeTraining = () => {
      emit('closeTraining');
    }

    // const sendRobotRequest = () => {
    //   document.dispatchEvent(new CustomEvent("CreateGuide", {detail: 0}));
    // }


    watch(() => viewOptions.value, (val) => {
      if (!val) {
        viewNavigation.value = false;
      }
    });


    onClickOutside(helpers, ({target}) => {
      if (document.getElementById('aboutObject')?.contains(target) || document.getElementById('helpersTrigger')?.contains(target)) return;
      viewOptions.value = false;
    })

    //----------------METHODS--------------------

    return {
      teleport,
      closeTraining,
      viewOptions,
      // sendRobotRequest,
      startTraining: () => {
        viewOptions.value = false;
        emit("startTraining");
      },
      openMap: () => {
        viewOptions.value = false;
        emit("openMap");
      },
      viewNavigation,
      viewSearchResults,
      currentObject,
      openObjectInfo,
      vdnxObjects,
      robotGuideEnable() {
        emit('robotGuideEnable');
        viewNavigation.value = false;
      },
      helpers
    };
  },
};
</script>

<style scoped>
.help-wrapper {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 2;
}

.help-filling {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.help-list-wrapper {
  width: 15.9375rem;
  padding: 0.62rem;
  border-radius: 0.5rem;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
  bottom: 5rem;
}

.help-list {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: #FFF;
}

.help-element {
  max-width: 100%;
  width: 100%;
  border-radius: 1rem;
  background: #ECF2FA;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.help-element-active::after {
  background: url("@/assets/icons/game/arrow-blue.svg");
}

.help-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.help-text {
  color: #003099;
  font-size: 1rem;
  font-weight: 600;
}

.help-arrow {
  margin-left: auto;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-shrink: 0;
}

.help-list-modal {
  position: absolute;
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  pointer-events: none;
}

.help-list-modal__open {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: all;
}

/* search */
.search-wrapper {
  position: absolute;
  right: -459px;
  top: -158px;
  max-width: 441px;
  width: 441px;
  z-index: 100;
}

.close {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  height: 48px;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  align-items: center;
  margin-left: auto;
}

.close-white {
  width: 20px;
  height: 20px;
}

.search-filling {
  max-width: 100%;
  width: 100%;
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.30000001192092896);
  border-radius: 25px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(85px);
}

.search__fild {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #828c99;
  border-radius: 8px;
  gap: 8px;
  padding: 12px 16px;
  height: 48px;
}

.search__input {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  height: 24px;
}

.search-popup__btn {
  margin-top: 28px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  min-height: 52px;
  gap: 8px;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 14px 16px;
  font-size: 17px;
}

.search-popup__btn-icon-active {
  transform: rotate(180deg);
}

.search-popup-list {
  margin-top: 4px;
}

.popup-list {
  overflow: auto;
  max-height: 200px;
  padding: 0 0px 0 0;
  scrollbar-width: thin;
  max-width: 100%;
  width: 100%;
}

.search-popup__element {
  padding: 8px 16px;
  font-size: 17px;
}

.search-popup__element-selected {
  background: #ddecff;
}

/* info */
.info-wrapper {
  position: absolute;
  z-index: 100;
  right: -576px;
  bottom: 0;
  max-width: 558px;
  width: 558px;
}

.info-filling {
  max-height: calc(100vh - 120px);
  background: rgba(255, 255, 255, 0.30000001192092896);
  border-radius: 25px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(85px);
  display: flex;
  flex-direction: column;
}

.info__img {
  max-width: 100%;
  width: 100%;
  height: 230px;
  background: rgba(0, 0, 0, 0.20000000298023224);
  border-radius: 24px 24px 0px 0px;
  object-fit: cover;
  object-position: center;
}

.info-content {
  padding: 20px 24px 40px 20px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
}

.info__name {
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  margin-top: 12px;
}

.info__about {
  margin-top: 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.info__text {
  margin-top: 16px;
  height: 100%;
  max-height: 60%;
  min-height: 40%;
  overflow: auto;
  line-height: 20px;
  scrollbar-width: thin;
  margin-bottom: 30px;
}

.info__btn {
  background: #003099;
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  margin-top: auto;
  padding: 16px;
}

.help__activate-wrapper {
  position: relative;
}

.help__activate {
  padding: 0.5rem;
  border-radius: 100%;
  background: rgba(157, 196, 248, 0.30);
  backdrop-filter: blur(4px);
}

.help__activate-icon {
  background: url("@/assets/icons/game/robot-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
}


.help__activate-training .help__activate-icon {
  background: url("@/assets/icons/game/robot-icon__active.svg");
  background-size: contain;
  background-repeat: no-repeat;

}

.help-activate-training {
  max-width: 577px;
  width: 577px;
  background: rgba(255, 255, 255, 0.36000001430511475);
  border-radius: 16px;
  backdrop-filter: blur(85px);
  padding: 20px 30px 14px 30px;
  top: -283px;
  position: absolute;
  z-index: 100;
}

.help-activate-training-caption {
  display: flex;
  justify-content: flex-end;
}

.help-activate-training__icon {
  width: 68px;
  height: 68px;
}

.help-activate-training-close {
  padding: 14px 1px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
}

.help-activate-training-close__absolute {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 0;
}

.help-activate-training-info {
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.44);
}

.help-activate-training-info__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.help-activate-training-info__text {
  max-width: 456px;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #ffffff;
}

.help-activate-training__btn {
  margin-top: 14px;
  background: #003099;
  border-radius: 8px;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  padding: 16px 36px;
  display: flex;
  margin-left: auto;
}

@media (max-width: 1000px) {
  .desktop {
    display: none;
  }

  .help__activate {
    padding: 0.25rem;
  }

  .help__activate-icon {
    width: 2rem;
    height: 2rem;
  }

  .help-wrapper {
    left: unset;
    bottom: unset;
    left: 4rem;
    top: 1rem;
  }

  .help-list-wrapper {
    bottom: unset;
    left: 3rem;
    padding: 0.37rem;
    border-radius: 0.5rem;
    width: 11rem;
  }

  .help-list {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .help-element {
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
  }

  .help-icon {
    width: 1rem;
    height: 1rem;
  }

  .help-text {
    font-size: 0.75rem;
  }

  .help-arrow {
    width: 1rem;
    height: 1rem;
  }

}
</style>
