export default [
  {
    "name": "Первый зал",
    "name_eng": "The first hall",
    "description": "Экспозиция павильона СИБУР рассказывает о том, как создаются материалы, которые делают нашу жизнь комфортной, безопасной и интересной. Только вдумайтесь: 8 из 10 предметов вокруг нас состоят из полимеров! 98% продуктов в мире производится с помощью химии. И для нашего комфортного быта: долговечные трубы для ЖКХ, утеплители, шумоизоляция, посуда, клей, лаки, декор – можно продолжать бесконечно.",
    "description_eng": "The exposition of the Pavilion Sibur talks about how the materials are created that make our life comfortable, safe and interesting.Just think: 8 out of 10 items around us consist of polymers!98% of products in the world are produced using chemistry.And for our comfortable life: durable pipes for housing and communal services, insulation, noise insulation, dishes, glue, varnishes, decor - you can continue endlessly.",
    "category": "1 - Зал",
    "width": "111",
    "height": "128",
    "left": "192",
    "top": "346",
    "imgUrl": `/pavilions/cibur-img-1.png`,
    "id": 113,
  },
  {
    "name": "Большая трансформация",
    "name_eng": "The first hall",
    "description": "Этот зал рассказывает, как создаются полимеры и где их можно встретить в повседневной жизни. Первые синтетические материалы появились в России всего несколько десятилетий назад и со временем изменили жизнь десятков миллионов людей. Сегодня из маленькой гранулы полимер можно превратить в многие привычные нам предметы быта, одежду, автомобили и даже медицинские средства. Посмотрите небольшой фильм – и вы узнаете о том, как развивалась советская нефтехимическая промышленность, с чем связано появление СИБУРа и какие новые задачи стоят перед российскими нефтехимиками.",
    "description_eng": "This hall tells how polymers are created and where they can be found in everyday life.The first synthetic materials appeared in Russia just a few decades ago and eventually changed the lives of tens of millions of people.Today, from the small granules, polymer can be turned into many household items, clothes, cars and even medical products.Watch a small film - and you will learn about how the Soviet petrochemical industry developed, which is related to the appearance of Siber and what new tasks are facing Russian petrochemists.",
    "category": "2 - Зал",
    "width": "146",
    "height": "164",
    "left": "10",
    "top": "393",
    "imgUrl": `/pavilions/cibur-img-2.png`,
    "id": 114,
  },
    {
    "name": "Мастера нефтехимии",
    "name_eng": "The first hall",
    "description": "В этом зале вы увидите тех, кто выбрал химию и нефтехимию делом своей жизни: Дмитрия Ивановича Менделеева и молодых профессионалов, которые трудятся в СИБУРе сегодня. А еще узнаете о том, какие профессии востребованы на производстве: от инженеров и математиков – до специалистов в сфере искусственного интеллекта и дизайнеров.",
    "description_eng": "In this hall you will see those who have chosen chemistry and petrochemistry of their life: Dmitry Ivanovich Mendeleev and young professionals who work in Sibur today.And you will also learn which professions are in demand in production: from engineers and mathematicians - to specialists in the field of artificial intelligence and designers.",
    "category": "3 - Зал",
    "width": "109",
    "height": "61",
    "left": "191",
    "top": "285",
    "imgUrl": `/pavilions/cibur-img-3.png`,
    "id": 115,
  },
    {
    "name": "Страна СИБУР",
    "name_eng": "The first hall",
    "description": "На стенах этого зала — география СИБУРа от Санкт-Петербурга до Благовещенска. Всего на интерактивную карту нанесены более 20 регионов присутствия компании. Вы сможете узнать, как города по всей стране вносят вклад в развитие полимерной промышленности и как СИБУР помогает этим городам становиться еще комфортнее для жизни.",
    "description_eng": "On the walls of this hall are the geography of Sibur from St. Petersburg to Blagoveshchensk.In total, more than 20 regions of the presence of the company have been applied to the interactive map.You can find out how cities throughout the country contribute to the development of the polymer industry and how Siber helps these cities become even more comfortable for life.",
    "category": "4 - Зал",
    "width": "122",
    "height": "122",
    "left": "339",
    "top": "386",
    "imgUrl": `/pavilions/cibur-img-4.png`,
    "id": 116,
  },
    {
    "name": "Экология// Практикум",
    "name_eng": "The first hall",
    "description": "Какие предметы можно получить из переработанного пластика? От новой бутылки для воды – до баскетбольного мяча! Подробнее о том, что делают из вторсырья и почему важно правильно сортировать отходы, вы узнаете в пятом зале. А ещё вы увидите капсулу сна, аналог которой используют сотрудники СИБУРа на производствах.",
    "description_eng": "What items can be obtained from processed plastic?From a new water bottle - to a basketball!Read more about what is done from recyclables and why it is important to correctly sort waste, you will find out in the fifth hall.And you will see a sleep capsule, an analogue of which is used by Sibura employees in production.",
    "category": "5 - Зал",
    "width": "172",
    "height": "122",
    "left": "177",
    "top": "118",
    "imgUrl": `/pavilions/cibur-img-5.png`,
    "id": 117,
  },
]