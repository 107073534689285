<template>
  <button class="place-btn" :class="{ 'place-btn__active': currentObject?.id === mapElement?.id }" :style="elementStyle"
    @click="openCurrentObject">
    <img src="@/assets/icons/game/position.svg" alt="" class="element-button__img ">
  </button>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed, ref } from "vue";
import { appConfig } from "@/lib/appConfig";

export default {
  name: "MapElement",
  computed: {
    appConfig() {
      return appConfig
    }
  },
  emits: ['teleport',
    'openCurrentObject',],
  props: ['mapElement', 'currentObject', 'index', 'locale'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const windowWidth = ref(window.innerWidth);

    window.onresize = () => {
      windowWidth.value = window.innerWidth;
    }

    const elementStyle = computed(() => {
      return {
        top: `${props.mapElement?.top}px`,
        left: `${props.mapElement?.left}px`,
        width: `${props.mapElement?.width}px`,
        height: `${props.mapElement?.height}px`,
      }
    })

    const teleportToLocation = () => {
      if (route.name !== 'gamePage') {
        // console.log(`TELEPORT RedesignAvailablePlaces.vue: ${props.mapElement?.id}`, props)
        sessionStorage.setItem('teleportId', props.mapElement?.id);
        router.push({ name: 'gamePage' });
      } else {
        // console.log(`TELEPORT RedesignAvailablePlaces.vue: ${props.mapElement?.id}`)
        // console.log(`%cteleport to mapElementId: ${props.mapElement?.id}, `, 'background: blue; font-size: 20px')
        emit('teleport', props.mapElement?.id);
      }
    }

    return {
      openCurrentObject() {
        emit('openCurrentObject', props.mapElement)
      },
      teleportToLocation,
      elementStyle,

    }
  }
}
</script>

<style scoped>
.place-btn:hover {
  opacity: 1;
}

.place-btn {
  position: absolute;
  box-shadow: 0px 24px 24px rgba(53, 113, 122, 0.015);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element-button__img {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  max-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.5s ease;
}


.place-btn img {
  opacity: 0;
  margin: 0;
  pointer-events: none;
}

.place-btn__active img {
  opacity: 1;
}

.place-btn:hover .element-button__img {
  opacity: 1;
}
</style>